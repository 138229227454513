// >>> Hotjar Tracking Code for https://www.schnitzersteel.com/service-lines/automotive-recycling-and-asset-recovery
// >>> pageId = 2465548

// >>> Hotjar Tracking Code for https://www.schnitzersteel.com/service-lines/recycling-and-certified-destruction
// >>> pageId = 2465545

// >>> Hotjar Tracking Code for https://www.schnitzersteel.com/service-lines/recycling-and-reclamation-for-national-accounts
// >>> pageId = 2465550

// >>> Hotjar Tracking Code for https://www.schnitzersteel.com/service-lines/rail-dismantling-and-recycling
// >>> pageId = 2465551

// >>> Hotjar Tracking Code for https://www.schnitzersteel.com/service-lines/reverse-logistics
// >>> pageId = 2465553

function hotjarTracking(pageId) {
  (function (h, o, t, j, a, r) {
    h.hj = h.hj || function () { (h.hj.q = h.hj.q || []).push(arguments) };
    h._hjSettings = { hjid: pageId, hjsv: 6 };
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script'); r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
}
